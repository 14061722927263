/* eslint-disable react/require-default-props */
/* eslint-disable no-void */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { ReactElement, useCallback, useEffect, useState } from 'react'

import useDebounce from '@utils/useDebounce'
import { ApolloError, QueryResult } from '@apollo/client'
import { Pagination } from '@damen/ui'
import LoadMoreLoader from './components/LoadMoreLoader/LoadMoreLoader'
import { SortingType } from './types'
import { Container, PaginationWrapper, TableWrapper } from './styles'

interface OrderBy {
	[column: string]: SortingType
}
interface LoadDataOptions<TFilters> {
	filters: TFilters
	searchQuery?: string
	orderBy: OrderBy
	page: number
}

interface Props<TVariables, TData, TFilters> {
	loadType?: 'default' | 'infinite'
	loadingText?: string
	loadData: (variables: LoadDataOptions<TFilters>) =>
		| Promise<QueryResult<TData, TVariables>>
		// We prefer ApolloClient responses, but only require the data/error
		| Promise<{ data: TData; error?: ApolloError }>
	// Only required when infinite loading is enabled
	mergeData?: (prev: TData, next: TData) => TData
	getTotalPages: (data: TData) => number
	shouldRenderHeader?: (data: {
		data: TData
		filters: TFilters
		searching: boolean
	}) => boolean
	renderHeader: (data: {
		data: TData
		filters: TFilters
		onChangeFilters: (filters: TFilters) => void
		searchQuery: string | undefined
		setSearchQuery: (searchQuery: string | undefined) => void
	}) => JSX.Element
	initialFilters?: TFilters
	renderTable: (data: {
		data: TData
		searchWord?: string
		orderBy: OrderBy
		setOrderBy: (orderBy: OrderBy) => void
	}) => JSX.Element
	renderError: (data: {
		type: 'noresult' | 'error'
		searchQuery: string | undefined
	}) => JSX.Element
	skeleton?: ReactElement
}

const GenericOverview = <
	TVariables extends Record<string, any>,
	TData extends object,
	TFilters extends Record<string, any>
>({
	loadData,
	mergeData,
	getTotalPages,
	loadType = 'default',
	loadingText,
	initialFilters,
	shouldRenderHeader,
	renderHeader,
	renderTable,
	skeleton,
	renderError
}: Props<TVariables, TData, TFilters>) => {
	const [isLoading, setIsLoading] = useState(true)
	const [filters, setFilters] = useState<TFilters>(
		initialFilters || ({} as TFilters)
	)
	const [searchQuery, setSearchQuery] = useState<string | undefined>()
	const [error, setError] = useState<ApolloError | undefined>(undefined)
	const debouncedSearchQuery = useDebounce(searchQuery, 500)
	const [orderBy, setOrderBy] = useState<
		| {
				[column: string]: SortingType
		  }
		| undefined
	>()
	const [page, setPage] = useState(1)

	const [data, setData] = useState<TData | undefined>()

	const load = useCallback(
		async (
			variables: LoadDataOptions<TFilters>,
			mergeData?: (prev: TData, next: TData) => TData
		) => {
			// Reset error state when we are loading new data
			setIsLoading(true)
			setError(undefined)

			try {
				const result = await loadData(variables)

				setError(result?.error)
				if (result.error) {
					setData(undefined)
				} else {
					setData((data) =>
						mergeData ? mergeData(data, result.data) : result.data
					)
				}
			} catch (error) {
				if (error.name === 'AbortError') {
					return
				}
				throw error
			} finally {
				setIsLoading(false)
			}
		},
		[loadData]
	)

	const totalPages = data ? Math.ceil(getTotalPages(data)) : undefined

	// Reload Data on Filter/Page/Sorting change
	useEffect(() => {
		load({
			filters,
			searchQuery: debouncedSearchQuery,
			orderBy,
			page
		})
		setInfiniteScrollPage(1)
	}, [debouncedSearchQuery, load, filters, orderBy, page])

	// Infinite scroll needs a separate page variable, because `page` is used
	// to load the initial page data, and the initial page never changes when
	// infinite scrolling. Look at infinite scrolling as adding more data, rather
	// than changing pages.
	const [infiniteScrollPage, setInfiniteScrollPage] = useState(1)
	const loadNextInfiniteScroll = async () => {
		if (!mergeData) {
			throw new Error(
				'Missing prop "mergeData", which is required for infinite scrolling'
			)
		}
		if (infiniteScrollPage >= totalPages) {
			return
		}

		load(
			{
				filters,
				searchQuery: debouncedSearchQuery,
				orderBy,
				page: infiniteScrollPage + 1
			},
			mergeData
		)
		setInfiniteScrollPage(infiniteScrollPage + 1)
	}

	const isNoResult = !isLoading && totalPages === 0
	const isError = !isLoading && error !== undefined
	const hasData = totalPages > 0

	// booleans to check if filters or searchquery are set
	const hasFilters = Object.keys(filters).some(
		(x) => filters[x] !== undefined
	)

	const searching =
		(debouncedSearchQuery !== undefined || hasFilters) ?? false

	// HasData check prevents showing the skeleton when it previously had data & is now loading new data (new page / search / filter)
	if (isLoading && skeleton && !hasData) {
		return skeleton
	}

	// * When making changes to this component make sure to test the following use cases:
	// 	1. render header when it has data
	// 	2. render header when a searchQuery or filters are set
	// 	3. DON'T render header when it has no data and no searchQuery or filters are set

	const defaultShouldRenderHeader = searching || hasData
	const shouldRenderHeaderValue = shouldRenderHeader
		? shouldRenderHeader({ data, filters, searching })
		: defaultShouldRenderHeader

	return (
		<Container>
			{shouldRenderHeaderValue && (
				<>
					{renderHeader({
						data,
						filters,
						onChangeFilters: (filters) => {
							setFilters(filters)
							setPage(1)
						},
						searchQuery,
						setSearchQuery: (string) => {
							setSearchQuery(string)
							setPage(1)
						}
					})}
				</>
			)}

			{hasData && (
				<TableWrapper>
					{data &&
						renderTable({
							data,
							searchWord: debouncedSearchQuery,
							orderBy,
							setOrderBy
						})}

					{totalPages > 1 && (
						<PaginationWrapper layout={loadType}>
							{loadType === 'default' && (
								<Pagination.Default
									initialIndex={page - 1}
									pages={totalPages}
									onClick={setPage}
								/>
							)}
							{loadType === 'infinite' && (
								<LoadMoreLoader
									key={infiniteScrollPage}
									loadNext={loadNextInfiniteScroll}
									isLoading={isLoading}
									loadingText={loadingText}
								/>
							)}
						</PaginationWrapper>
					)}
				</TableWrapper>
			)}

			{isNoResult &&
				renderError({
					type: 'noresult',
					searchQuery: debouncedSearchQuery
				})}

			{isError &&
				renderError({
					type: 'error',
					searchQuery: debouncedSearchQuery
				})}
		</Container>
	)
}

export default GenericOverview
